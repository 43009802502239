import React from 'react';
import { Parallax } from 'react-parallax';
import YouTubePlayer from './youtubeplayer';
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';


const Section = () => {
   const videoId = 'fWwNNTjZiY0';
    return(
        <Parallax className="home-video">
                <div className="iframeyoutube"><YouTubePlayer videoId={videoId} /></div>
                
                <div className="de-gradient-edge-top"></div>
                <div className="de-gradient-edge-bottom"></div>
                <div className="overlay-bg t50 no-top no-bottom">
                    <div className="v-center">
                        <div className="container z-1000">
                            <div className="row align-items-center">                        
                                <div className="col-lg-10 offset-lg-1 text-center">
                                    <div className="subtitle blink mb-4">31.10.2024</div>
                                    <h1 className="mb-0 "><Countdown date={new Date('2024-10-31T00:00:00')} /></h1>                                    
                                </div>
                                <div className="col-lg-6 offset-lg-3 text-center text-white">
                                    <p className="">Our services are currently undergoing maintenance to improve performance and reliability. We’re working hard to minimize downtime and ensure everything is back up and running smoothly. Thank you for your patience during this process.</p>
                                    <Link className="btn-main" to="https://discord.gg/arkadeone-host-play-mod-share-967431124764745800">Join Discord</Link><p></p>
				    <Link className="btn-main" to="https://www.youtube.com/watch?v=fWwNNTjZiY0">Watch Trailer</Link>

                                    <div className="spacer-single"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Parallax>
    );
}

export default Section;