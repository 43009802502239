import React from 'react';

const Sectionloc = () => {
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="subtitle fadeInUp mb-3">Server locations</div>
                </div>
                <div className="col-lg-6">
                    <h2 className="fadeInUp mb20">6 server locations across the globe for seamless gaming.</h2>
                </div>
                <div className="col-lg-6">
                   
Ensure optimal performance by choosing the right server location for your game. With players spread across different regions, it's essential to select the server that delivers the best experience. By strategically placing servers, you can offer faster, more reliable gameplay, ensuring a smoother experience for your global audience.
                </div>

                <div className="spacer-10"></div>

                <div className="col-lg-12 fadeInUp">
                    <div className="p-sm-30 pb-sm-0 mb-sm-0">
                        <div className="de-map-hotspot">
                            <div className="de-spot fadeIn" style={{top:'39%', left:'20%',}}>
                                <span>United&nbsp;States</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <div className="de-spot fadeIn" style={{top:'76%', left:'87%'}}>
                                <span>Australia</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <div className="de-spot fadeIn" style={{top:'68%', left:'80%'}}>
                                <span>Singapore</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <div className="de-spot fadeIn" style={{top:'23%', left:'18%'}}>
                                <span>Canada</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <div className="de-spot fadeIn" style={{top:'23%', left:'51%'}}>
                                <span>Sweden</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <div className="de-spot fadeIn" style={{top:'36.4%', left:'48.7%'}}>
                                <span>Germany</span>
                                <div className="de-circle-1"></div>
                                <div className="de-circle-2"></div>
                            </div>
                            <img src="./img/misc/world-map.webp" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>                        
            </div>
        </div>
        <div className="no-bottom wow fadeInRight d-flex z-1000">
          <div className="de-marquee-list wow">
            <div className="d-item">
              <span className="d-item-txt">Toronto</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Frankurt</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Amsterdam</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Stockholm</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Los Angeles</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Idaho</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Singapore</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
              <span className="d-item-txt">Sydney</span>
              <span className="d-item-display">
                <i className="d-item-block"></i>
              </span>
             </div>
          </div>
        </div>
        </>
    );
}

export default Sectionloc;