import React, { useEffect } from 'react';

const ChatwootWidget = () => {
  useEffect(() => {
    (function(d, t) {
      var BASE_URL = "https://sp.arkade.one";
      var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function() {
        window.chatwootSDK.run({
          websiteToken: '3SrKJU6crg1yXLDrqbFQ98ga',
          baseUrl: BASE_URL
        });
      };
    })(document, "script");
  }, []);

  return null; // No need to render anything for this widget
};

export default ChatwootWidget;
