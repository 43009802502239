import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

const NavLink = (props) => {
  return <Link {...props} />;
};

const Navbar = () => {
  const useDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    const ref = useRef(null);
    useOnclickOutside(() => {
      closeDropdown();
    }, ref);

    return {
      isOpen,
      toggleDropdown,
      closeDropdown,
      ref,
    };
  };

const { isOpen: openMenu, toggleDropdown: handleBtnClick, closeDropdown: closeMenu, ref } = useDropdown();
const { isOpen: openMenu1, toggleDropdown: handleBtnClick1, closeDropdown: closeMenu1, ref: ref1 } = useDropdown();
const { isOpen: openMenu2, toggleDropdown: handleBtnClick2, closeDropdown: closeMenu2, ref: ref2 } = useDropdown();
const { isOpen: openMenu3, toggleDropdown: handleBtnClick3, closeDropdown: closeMenu3, ref: ref3 } = useDropdown();
const { isOpen: openMenu4, toggleDropdown: handleBtnClick4, closeDropdown: closeMenu4, ref: ref4 } = useDropdown();

  const [showmenu, setBtnIcon] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;

    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    };

    window.addEventListener("scroll", scrollCallBack);

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <nav className="navbar transition">
        <div className="container">
          <NavLink  className="navbar-brand" to="/">
            <img src="./img/logo.png" style={{ height: '52px', width: '222px' }} className="img-fluid d-md-block d-none imginit" alt="logo"/>
            <img src="./img/logo.png" style={{ height: '42px', width: '178px' }} className="img-fluid d-md-none d-sms-none imginit" alt="logo"/>
          </NavLink>
              <div className="mobile">
                {showmenu && 
                <div className='menu'>

                  <div className='navbar-item counter'>
                    <div ref={ref}>
                      <div className="dropdown-custom dropdown-toggle btn" 
                        onClick={() => {
                                        handleBtnClick();
                                        closeMenu1();
                                        closeMenu2();
                                        closeMenu3();
                                        closeMenu4();
                                      }}>
                        Products
                      </div>
                      {openMenu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu}>
                            <NavLink to="/games" onClick={() => setBtnIcon(!showmenu)}>Gameserver</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  
                  <div className='navbar-item counter'>
                      <NavLink to="/news" onClick={() => setBtnIcon(!showmenu)}>
                      News
                      </NavLink>
                  </div>

                  <div className='navbar-item counter'>
                    <div ref={ref1}>
                      <div className="dropdown-custom dropdown-toggle btn" 
                        onClick={() => {
                                        handleBtnClick1();
                                        closeMenu();
                                        closeMenu2();
                                        closeMenu3();
                                        closeMenu4();
                                      }}>
                        Support
                      </div>
                      {openMenu1 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to="/faq" onClick={() => setBtnIcon(!showmenu)}>FAQ</NavLink>
                            <NavLink to="/contact" onClick={() => setBtnIcon(!showmenu)}>Contact Us</NavLink>
                            <NavLink to="/pricing2" onClick={() => setBtnIcon(!showmenu)}>Customer Support</NavLink>
                            <NavLink to="/pricing3" onClick={() => setBtnIcon(!showmenu)}>Diiscord Server</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>


                    <div className='navbar-item counter'>
                      <div ref={ref3}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={() => {
                                        handleBtnClick3();
                                        closeMenu1();
                                        closeMenu2();
                                        closeMenu();
                                        closeMenu4();
                                      }}>
                          Company
                        </div>
                        {openMenu3 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu3}>
                              <NavLink to="/about" onClick={() => setBtnIcon(!showmenu)}>About Us</NavLink>
                              <NavLink to="/locations" onClick={() => setBtnIcon(!showmenu)}>Locations</NavLink>
                              <NavLink to="/affiliate" onClick={() => setBtnIcon(!showmenu)}>Affiliate Program</NavLink>
                              <NavLink to="/press" onClick={() => setBtnIcon(!showmenu)}>Media & Presskit</NavLink>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  <div className='navbar-item counter'>
                      <NavLink to="/news" onClick={() => setBtnIcon(!showmenu)}>
                      News
                      </NavLink>
                  </div>

                </div>
                }
          </div>
          <div className="dekstop">
          <div className='menu'>

            <div className='navbar-item counter'>
                <div ref={ref}>
                  <div className="dropdown-custom dropdown-toggle btn" 
                     onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                    Products
                    {openMenu && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu}>
                        <NavLink to="/games">Gameserver</NavLink>
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
            </div>

            <div className='navbar-item counter'>
              <NavLink to="/news">
              News
              </NavLink>
            </div>

            <div className='navbar-item counter'>
                <div ref={ref2}>
                  <div className="dropdown-custom dropdown-toggle btn" 
                     onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
                    Support
                    {openMenu2 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu2}>
                        <NavLink to="/faq">FAQ</NavLink>
                        <NavLink to="/contact">Contact Us</NavLink>
                        <NavLink to="/faq">Customer Support</NavLink>
                        <NavLink to="/contact">Discord Server</NavLink>
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
            </div>

            <div className='navbar-item counter'>
                <div ref={ref3}>
                  <div className="dropdown-custom dropdown-toggle btn" 
                     onMouseEnter={handleBtnClick3} onMouseLeave={closeMenu3}>
                    Company
                    {openMenu3 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu3}>
                        <NavLink to="/about">About Us</NavLink>
                        <NavLink to="/locations">Locations</NavLink>
                        <NavLink to="/affiliate">Affiliate Program</NavLink>
                        <NavLink to="https://cdn.arkade.one/files/press.zip">Media & Presskit</NavLink>
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
            </div>

            </div>
          </div>
          <div className="menu_side_area">
<div className="dekstop">
              <NavLink to="https://my.arkade.one" className="btn-line" id="btn-line">Get Started</NavLink>
</div>
              <button className="burgermenu" type="button" 
                onClick={() => {
                  setBtnIcon(!showmenu);
                  closeMenu1();
                  closeMenu2();
                  closeMenu3();
                  closeMenu4();
                }}>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
          </div>
        </div>
      </nav>
    )
}

export default Navbar;